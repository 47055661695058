/**
 * 网络请求配置
 */
import axios from "axios"
import { message } from 'antd'
import qs from 'qs'

axios.defaults.withCredentials = true
axios.defaults.timeout = 100000
let base = 'https://test.fanwoon.com'

if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = "/api"
} else {
    axios.defaults.baseURL = base + "/oms"
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

axios.interceptors.request.use(

    (config) => {
        /*  config.data = JSON.stringify(config.data);
         config.post = {
             "Content-Type": "application/json",
             "path": "companyLogo",
         } */
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            (response) => {
                //关闭进度条
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}